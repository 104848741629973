import { throwErrorResponse } from "@/helpers";
import { getRegulatoryActionsData } from "@/http/api/regulatory-updates";
import { type LoaderFunctionArgs } from "react-router-dom";

export default async function regulatoryActionsLoader({
  params,
}: LoaderFunctionArgs) {
  try {
    const { regulatoryUpdateId } = params;

    if (!regulatoryUpdateId) {
      throwErrorResponse({
        status: 404,
        statusText:
          "We couldn't find the regulatory actions. Please contact support.",
      });
      return;
    }
    const response = await getRegulatoryActionsData(regulatoryUpdateId);
    return response;
  } catch (e) {
    console.log(e);
    throwErrorResponse({
      status: 404,
      statusText:
        "We couldn't find the regulatory actions. Please contact support.",
    });
  }
}
